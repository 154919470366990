/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
// import './styles/antd_styles.less';
import './styles/antd_styles.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// Use consistent styling
import 'sanitize.css/sanitize.css';
// Initialize languages
import './locales/i18n';

import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import { BrowserView, MobileView } from 'react-device-detect';

import FontFaceObserver from 'fontfaceobserver';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styles/theme/ThemeProvider';
import { configureAppStore } from 'store/configureStore';
import { lazyLoad } from 'utils/loadable';
import reportWebVitals from 'reportWebVitals';
import ReactGA from 'react-ga4';
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactGA.initialize('G-E8B856ZZTV');

const SendAnalytics = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
  });
};

const WebApp = lazyLoad(() => import('webApp'));

const MobileApp = lazyLoad(() => import('mobileApp'));

// Observe loading of Source Sans Pro (to remove 'Source Sans Pro', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Source Sans Pro', {});

// When Source Sans Pro is loaded, add a font-family using Source Sans Pro to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root');

const ReactRoot = ReactDOM.createRoot(MOUNT_NODE);

if (process.env.REACT_APP_ENV !== 'test') {
  console.log = function () {};
}

ReactRoot.render(
  <GoogleOAuthProvider clientId="937690079602-31i5qjthfnbo8nea4s1h4fm8ttj3c11r.apps.googleusercontent.com">
    <Provider store={store}>
      <ThemeProvider>
        <HelmetProvider>
          <BrowserView>
            <WebApp />
          </BrowserView>
          <MobileView>
            <MobileApp />
          </MobileView>
        </HelmetProvider>
      </ThemeProvider>
    </Provider>
  </GoogleOAuthProvider>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
