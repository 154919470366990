import { convertLanguageJsonToObject } from './translations';
import en from './en/translation.json';
import hi from './hi/translation.json';

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(en);

export const languageList = [
  {
    langKey: 'en',
    langName: 'English',
    translation: en,
  },
  {
    langKey: 'hi',
    langName: 'हिन्दी',
    translation: hi,
    langEnglishName: 'Hindi',
  },
];
