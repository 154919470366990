const commonColors = {
  white: '#ffffff',
  black: '#000000',
  black85: '#000000D9',
  black65: '#000000A6',
  black35: '#00000059',
  black10: '#0000001A',
  greyback: '#fafafa',
  blogtag: '#FD7272',
  noticetag: '#82589F',
  qandatag: '#FC427B',
  newstag: '#353b48',
  polltag: '#B33771',
  generaltag: '#1B9CFC',
  lessontag: '#FA9D45',
  successColor: '#00b578',
  warningColor: '#ff8f1f',
  dangerColor: '#ff3141',
  btnBlue: '#0084FF',
  linkColor: '#0084FF',
  appColorTeal: '#1FC498',
};

const lightTheme = {
  isDark: false,
  primary: '#181818',
  secondary: '#ffffff', // Example color, replace with appropriate secondary color
  text: '#181818',
  textSecondary: '#808080', // Example color, replace with appropriate textSecondary color
  background: '#ffffff',
  backgroundVariant: '#f0f0f0', // Example color, replace with appropriate backgroundVariant color
  border: '#dcdcdc', // Example color, replace with appropriate border color
  borderLight: '#f5f5f5', // Example color, replace with appropriate borderLight color
  listItemBgColor: '#f5f5f5', // Example color, replace with appropriate listItemBgColor
  ...commonColors,
};

const darkTheme = {
  isDark: true,
  primary: '#ffffff', // Invert primary color
  secondary: '#181818', // Example color, replace with appropriate secondary color
  text: '#ffffff', // Invert text color
  textSecondary: '#dcdcdc', // Example color, replace with appropriate textSecondary color
  background: '#181818', // Invert background color
  backgroundVariant: '#000000', // Example color, replace with appropriate backgroundVariant color
  border: '#000000', // Example color, replace with appropriate border color
  borderLight: '#121212', // Example color, replace with appropriate borderLight color
  listItemBgColor: '#121212', // Example color, replace with appropriate listItemBgColor
  ...commonColors,
};

const defaultTheme = {
  companyTitle: 'Cleandesk',
  companyLogo: '/logo-emoji-black.svg?x=10000000',
  // companyLogo: '/logo96tranparent.png?x=10000000',
  isDark: false,
  primary: '#181818',
  secondary: '#ffffff', // Example color, replace with appropriate secondary color
  text: '#181818',
  textSecondary: '#808080', // Example color, replace with appropriate textSecondary color
  background: '#ffffff',
  backgroundVariant: '#f0f0f0', // Example color, replace with appropriate backgroundVariant color
  border: '#dcdcdc', // Example color, replace with appropriate border color
  borderLight: '#f5f5f5', // Example color, replace with appropriate borderLight color
  listItemBgColor: '#f5f5f5', // Example color, replace with appropriate listItemBgColor
  ...commonColors,
};

export type Theme = typeof lightTheme;

export const themes = {
  light: lightTheme,
  dark: darkTheme,
  default: defaultTheme,
};

export const AddNewTheme = (orgUiDetails: any, key: string, myCallback) => {
  const newTheme = {
    companyTitle: orgUiDetails.title,
    companyLogo: orgUiDetails.logo || '/logo-white.png',
    isDark: false,
    primary: orgUiDetails.primary_color || '#181818',
    secondary: '#4F68F7',
    text: 'rgba(58,52,51,1)',
    textSecondary: 'rgba(58,52,51,0.7)',
    background: 'rgba(255,255,255,1)',
    backgroundVariant: 'rgba(251,249,249,1)',
    border: 'rgba(58,52,51,0.12)',
    borderLight: 'rgba(58,52,51,0.05)',
    listItemBgColor: orgUiDetails.primary_color + '10',
  };
  themes[key] = { ...newTheme, ...commonColors };

  myCallback(key);
};
